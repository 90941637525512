import { Dispatch } from "redux";
import { ActionsType, ISubscriptionActions } from "./types";
import {
  getCourseSubRequest,
  getArcadesInfoRequest,
  getSubAccessesRequest,
  getSubArcadeAccessRequest,
} from "./api";
import { toCamelCase } from "../../../helpers";
import { History } from "history";
import login from '../../../pages/auth/Login';

export const actions = {
  setLoading: (value: boolean) => ({ type: "payment/LOADING", value } as const),
  setCourseSub: (payload: any) =>
    ({ type: "payment/SET_COURSE_SUB", payload } as const),
  setArcadesSub: (payload: any) =>
    ({ type: "payment/SET_ARCADES_SUB", payload } as const),
  setSubAccesses: (payload: any) =>
    ({ type: "payment/SET_SUB_ACCESSES", payload } as const),
};

export const getCourseSub =
  (childId: number, history: History) => (dispatch: Dispatch<ActionsType>) => {
    dispatch(actions.setLoading(true));

    const courseId = localStorage.getItem("courses_id")!;

    return getCourseSubRequest(childId, +courseId).then((res: any) => {
      if (res.status === 200) {
        dispatch(
          actions.setCourseSub(
            toCamelCase({
              ...res.data,
              actions: res.data.actions.map((item: ISubscriptionActions) =>
                toCamelCase(item)
              ),
            })
          )
        );
      }
      if (res.status === 401 || res.status === 404) {
        history.push("/home");
      }
      dispatch(actions.setLoading(false));
    });
  };

export const getArcadesInfo =
  (childId: number, history: History) => (dispatch: Dispatch<ActionsType>) => {
    dispatch(actions.setLoading(true));

    return getArcadesInfoRequest(childId).then((res: any) => {
      if (res.status === 200) {
        dispatch(actions.setArcadesSub(toCamelCase(res.data)));
      }
      if (res.status === 401 || res.status === 403 || res.status === 404) {
        history.push("/");
      }
      dispatch(actions.setLoading(false));
    });
  };

export const getSubAccesses =
  (
    childId: number,
    action: string,
    payPeriod: string | null,
    history: History
  ) =>
  (dispatch: Dispatch<ActionsType>) => {
    dispatch(actions.setLoading(true));

    const courseId = localStorage.getItem("courses_id")!;
    let params: any = {};

    if (payPeriod) {
      params.pay_period = payPeriod;
    }

    return getSubAccessesRequest(
      childId,
      +courseId,
      action,
      payPeriod ? params : null
    ).then((res: any) => {
      if (res.status === 200 && res.data.data) {
        dispatch(actions.setSubAccesses(res.data));
      }
      if (res.status === 200 && res.data.actions) {
        dispatch(
          actions.setCourseSub(
            toCamelCase({
              ...res.data,
              actions: res.data.actions.map((item: ISubscriptionActions) =>
                toCamelCase(item)
              ),
            })
          )
        );
      }
      if (res.status === 401 || res.status === 403 || res.status === 404) {
        history.push("/");
      }
      dispatch(actions.setLoading(false));
    });
  };

export const getSubArcadeAccess =
  (childId: number, action: string, history: History) =>
  (dispatch: Dispatch<ActionsType>) => {
    dispatch(actions.setLoading(true));

    return getSubArcadeAccessRequest(childId, action).then((res: any) => {
      if (res.status === 200 && res.data.data) {
        dispatch(actions.setSubAccesses(res.data));
      }
      if (res.status === 200 && action === "unsubscribe") {
        dispatch(actions.setArcadesSub(toCamelCase(res.data)));
      }
      if (res.status === 401 || res.status === 403 || res.status === 404) {
        history.push("/");
      }
      dispatch(actions.setLoading(false));
    });
  };
