import React, { useMemo } from "react";
import LevelElement from "../LevelElement";
import { ILevelsData } from "../../../../../store/modules/studyZone/types";
import { Authorization } from "../../../../../store/modules/auth/types";

interface IProps {
  levels: ILevelsData[];
  choseLesson: (id: number) => void;
  auth: Authorization | null;
}
/**
 *
 * @param levels{Object} - Redux value. List of levels with pagination params from api.
 * @param choseLesson{function} - function in the parent component
 * @param auth{'authorized' || 'notAuthorized'} - Redux value. Show auth user or no
 * @returns {JSX.Element}
 * @constructor
 */

const LevelList: React.FC<IProps> = ({ levels, choseLesson, auth }) => {
  const getElement = useMemo(() => {
    if (levels) {
      return levels.map((item) => {
        return (
          <LevelElement
            item={item}
            key={item.id}
            auth={auth}
            choseLesson={choseLesson}
          />
        );
      });
    }

    return null;
  }, [levels, auth, choseLesson]);

  return <div className="grid-list">{getElement}</div>;
};

export default LevelList;
