import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import cn from "classnames";
import RoundButton from "../../../../../components/buttons/RoundButton";
import youTubeIcon from "../../../../../assets/icons/youTube_icon.svg";
import bollIcon from "../../../../../assets/icons/ball.svg";
import cub from "../../../../../assets/icons/home/cubes.svg";
import home from "../../../../../assets/icons/home.svg";
import starBoy from "../../../../../assets/icons/girl_stars.svg";
import greenArrow from "../../../../../assets/icons/green_arrow.svg";
import ClickHandlerPage from "../../../../../components/ClickHandlerPage";
import {
	animationHandlerClick,
	animationStart, checkAnimationId,
	endOfAnimatedAudio, game_game_id, lesson_lesson_id, removeAudio, shouldShowAnimation, shouldShowTouchScreen,
} from "../../../../../helpers";

const audioGirl = require("../../../../../assets/audio/lesson_main_screen.mp3");

interface Props {
	setIsWatchedVideo: (prev: any) => void;
	completedPart: () => void;
	isVideoNext: boolean;
	levelId: string;
}

const LessonMainScreen: React.FC<Props> = ({
																						 setIsWatchedVideo,
																						 completedPart,
																						 isVideoNext,
																						 levelId,
																					 }) => {
	const [isAnimation, setIsAnimation] = useState(checkAnimationId(lesson_lesson_id));
	const history = useHistory();
	let audio: MutableRefObject<HTMLAudioElement | null> = useRef(null);
	const [isTouchScreen, setIsTouchScreen] = useState(false);

	console.log("REDNER IS ANIMATION", isAnimation);

	useEffect(() => {
		if (checkAnimationId(lesson_lesson_id)) {
			animationStartButton();
		}
		return () => {
			if (audio.current) removeAudio(audio.current);
			endOfAnimatedAudio(audio.current, setIsAnimation, lesson_lesson_id);
		};
	}, []);

	const animationStartButton = () => {
		console.log('animationStartButton')
		audio.current = new Audio(audioGirl);
		setIsTouchScreen(false);
		animationStart(audio.current!, setIsAnimation, lesson_lesson_id, () => {
			setIsTouchScreen(true)
		});
	};

	const getNextButton = () => {
		if (isVideoNext) {
			return (
				<RoundButton
					image={greenArrow}
					onClick={() => animationHandlerClick(isAnimation, completedPart)}
					alt="boll"
					className={cn("lesson__circle-button", {
						"lesson__main-screen_ball-animation": isAnimation,
					})}
					imageClassName="lesson__circle-image green-arrow-image"
				/>
			);
		}

		return (
			<RoundButton
				image={bollIcon}
				onClick={() => animationHandlerClick(isAnimation, completedPart)}
				alt="boll"
				className={cn("lesson__circle-button", {
					"lesson__main-screen_ball-animation": isAnimation,
				})}
				imageClassName="lesson__circle-image"
			/>
		);
	};

	if (isTouchScreen) {
		return <ClickHandlerPage animationStartButton={animationStartButton}/>;
	}

	return (
		<div className="vertical-screen">
			<div className="lesson">
				<div className="lesson-buttons flex-center">
					<RoundButton
						image={youTubeIcon}
						onClick={() => {
							animationHandlerClick(isAnimation, () =>
								setIsWatchedVideo(false)
							);
						}}
						alt="youtube"
						className={cn("lesson__circle-button", {
							"lesson__main-screen_video-animation": isAnimation,
						})}
						imageClassName="lesson__circle-image"
					/>

					{getNextButton()}
				</div>
				<RoundButton
					image={cub}
					onClick={() =>
						animationHandlerClick(isAnimation, () =>
							history.push(`/levels/${levelId}`)
						)
					}
					alt="next arrow"
					className={cn("lessons-nav_circle position__right-bottom", {
						"lesson__main-screen-cub": isAnimation,
					})}
					imageClassName="lessons-nav-image"
				/>
				<RoundButton
					image={home}
					onClick={() => {
						animationHandlerClick(isAnimation, () => history.push("/home"));
					}}
					alt="home"
					className={cn("position__right-top lessons-nav_circle", {
						"lesson__main-screen-home": isAnimation,
					})}
					imageClassName="lessons-nav_circle-house lessons-nav-image"
				/>
				<img
					src={starBoy}
					alt="star"
					className={cn("lesson-star", {
						"lesson__main-screen_girl": isAnimation,
					})}
				/>
			</div>
		</div>
	);
};

export default LessonMainScreen;
