import {
  ActionsType,
  ISubAccesses,
  ISubArcadesInfo,
  ISubCourseInfo,
} from "./types";

const InitialState = {
  loading: false,
  subCourseInfo: null as ISubCourseInfo | null,
  subArcadesInfo: null as ISubArcadesInfo | null,
  subAccesses: null as ISubAccesses | null,
};

export type InitialStateType = typeof InitialState;

export const PaymentReducer = (
  state = InitialState,
  action: ActionsType
): InitialStateType => {
  switch (action.type) {
    case "payment/LOADING":
      return {
        ...state,
        loading: action.value,
      };
    case "payment/SET_COURSE_SUB":
      return {
        ...state,
        subCourseInfo: action.payload,
      };
    case "payment/SET_ARCADES_SUB":
      return {
        ...state,
        subArcadesInfo: action.payload,
      };
    case "payment/SET_SUB_ACCESSES":
      return {
        ...state,
        subAccesses: action.payload,
      };
    default:
      return state;
  }
};
