import React from "react";
import FinalCub_1 from "../../assets/icons/cubs/Final_cub.svg";
import RoundButton from "../../components/buttons/RoundButton";
import { useHistory } from "react-router-dom";
import arrowNext from "../../assets/icons/blue-arrow-top.svg";
import "./mainCub.scss";

interface IProps {}

const MainCub: React.FC<IProps> = () => {
  const history = useHistory();

  return (
    <div className="cub">
      <div className="vertical-screen">
        <object
          style={{ width: "100%", height: "100%" }}
          data={FinalCub_1}
          type=""
        />

        <RoundButton
          className="position__right-bottom lessons-nav_circle transform-arrow"
          imageClassName="blue-arrow-image lessons-nav-image"
          image={arrowNext}
          alt="question"
          onClick={() => history.push("/settings/completed")}
        />
      </div>
    </div>
  );
};

export default MainCub;
