import request from "../../request";
import {
  IResetProfilePassword,
  ISetCertificate,
  ISetEmail,
  ISetKeyword,
} from "./types";

export const getProfileRequest = () => {
  return request.get("profile");
};

export const setKeywordRequest = (params: ISetKeyword) => {
  return request.patch("profile/key-word", params);
};

export const resetProfilePasswordRequest = (params: IResetProfilePassword) => {
  return request.patch("profile/password", params);
};

export const setEmailRequest = (params: ISetEmail) => {
  return request.patch("profile/email", params);
};

export const resendEmailRequest = () => {
  return request.post("email/resend");
};

export const resetKeywordRequest = () => {
  return request.post("profile/reset-keyword");
};

export const setCertificateRequest = (
  activeChildId: number | null,
  params: ISetCertificate
) => {
  return request.post(`leads/send-to-crm/${activeChildId}`, params);
};
