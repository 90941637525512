import request from "../../request";
import { IChildRequest, IChildrenHeader, ISaveProgress } from "./types";

export const addChildRequest = (
  params: IChildRequest,
  option: IChildrenHeader
) => {
  return request.postData("children", params, option);
};

export const upgradeChildRequest = (
  childId: number,
  params: IChildRequest,
  option: IChildrenHeader
) => {
  return request.postData(`children/${childId}?_method=PUT`, params, option);
};

export const getChildrenRequest = () => {
  return request.get("children");
};

export const getChildDetailsRequest = (childId: number) => {
  return request.get(`children/${childId}`);
};

export const removeChildRequest = (childId: number) => {
  return request.delete(`children/${childId}`);
};

export const saveProgressRequest = (childId: number, params: ISaveProgress) => {
  return request.post(`children/${childId}/progress`, params);
};
