import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { History } from 'history';

export interface ILocation {
	partId?: string | null
	to?: string;
}

const useHistoryWithReplace = (): [History, (key: string) => void, ILocation] => {
	const history: History = useHistory();
	const { state } = useLocation<ILocation>();

	const replaceStore = (key: string) => {
		// @ts-ignore
		if (history.location.state && history.location.state.hasOwnProperty(key)) {
			// @ts-ignore
			const state = {...history.location.state};
			delete state.partId;
			history.replace({...history.location, state})
		}
	}

	return [history, replaceStore, state]
}

export default useHistoryWithReplace;
