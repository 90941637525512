import i18n from 'i18next';

export const requiredValidate = value => {
  return value || typeof value === 'number' ? undefined : i18n.t('Validation.requireField');
};

export const emailValidate = value => {
  return value && !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(value.trim())
    ? i18n.t('Validation.notValidEmail')
    : undefined;
};

export const passwordValidate = value => {
  if (value && value.match(/[а-яА-ЯІі]+/)) {
    return i18n.t('Validation.isCyrillic');
  }
  if (value && (value.length < 6 || !value.match(/([A-Z]|[a-z])/) || !value.match(/[0-9]/))) {
    return i18n.t('Validation.mainPassword');
  }
  return undefined;
};

export const validationNumber = value => {
  if (value && !value.match(/^\+380\d{3}\d{2}\d{2}\d{2}$/)) {
    return i18n.t('Validation.validationNumber');
  }
  return undefined;
};

export const minLengthCreator = minLength => value => {
  if (value && value.length < minLength) {
    return `${i18n.t('Validation.minLength.part1')} ${minLength} ${i18n.t(
      'Validation.minLength.part2'
    )}`;
  }
  return undefined;
};
export const maxLengthCreator = maxLength => value => {
  if (value && value.length > maxLength) {
    return `${i18n.t('Validation.maxLength.part1')} ${maxLength} ${i18n.t(
      'Validation.maxLength.part2'
    )}`;
  }
  return undefined;
};

/**
 * Sync validate. Needs for confirm password Field.
 *
 *
 * @param {Object} formProps - Value in SignIn Form
 * @returns {{}} - return error when field is empty or field password !== field confirmPassword if conditions are satisfactory return empty object
 */
export const validate = formProps => {
  const error = {};

  if (!formProps.confirmPassword) {
    error.confirmPassword = i18n.t('Validation.requireField');
  } else if (formProps.confirmPassword !== formProps.password) {
    error.confirmPassword = i18n.t('Validation.passwordNotMatch');
  }
  return error;
};
