import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import RoundButton from "../../../../../components/buttons/RoundButton";
import arrow from "../../../../../assets/icons/yellow_back_arrow.svg";
import homeIcon from "../../../../../assets/icons/home.svg";
import bronze from "../../../../../assets/icons/cubs/white_bacground_bronze.svg";
import gold from "../../../../../assets/icons/cubs/white_bacground_gold.svg";
import silver from "../../../../../assets/icons/cubs/white_bacground_silver.svg";
import emptyCub from "../../../../../assets/icons/cubs/white_background_empty.svg";
import star from "../../../../../assets/icons/star.svg";
import { getCompleteLesson } from "../../../../../helpers";
import { ILessonList } from "../../../../../store/modules/studyZone/types";
import { Authorization } from "../../../../../store/modules/auth/types";
import "../../lessonsList.scss";

interface Props {
  lessonsList: ILessonList[];
  prevComplete: number | null;
  auth: Authorization | null;
}

/**
 *
 * @param lessonsList{Array} - Redux value. List of lesson from api.
 * @param auth{'authorized'||'notAuthorized } - Redux value. Get auth user or no.
 * @param prevComplete{number} - Redux value. Number of completed lesson
 * @returns {JSX.Element}
 * @constructor
 */

const LessonsNav: React.FC<Props> = ({ lessonsList, prevComplete, auth }) => {
  const [cubClassName, setCubClassName] = useState("lessons-nav_cub-image");
  const history = useHistory();

  useEffect(() => {
    if (auth === "authorized") {
      const lessonsLength = lessonsList.length;

      if (
        (getCompleted === lessonsLength - 3 &&
          prevComplete === lessonsLength - 4) ||
        (getCompleted === lessonsLength - 1 &&
          prevComplete === lessonsLength - 2) ||
        (getCompleted === lessonsLength && prevComplete === lessonsLength - 1)
      ) {
        setCubClassName("lessons-nav_cub-image cub-animation");
      }
    }

    if (auth === "notAuthorized" && sessionStorage.getItem("animation_cub")) {
      sessionStorage.removeItem("animation_cub");
      setCubClassName("lessons-nav_cub-image cub-animation");
    }
  }, [prevComplete, auth]);

  const getCompleted = useMemo(() => {
    if (auth === "authorized") {
      return getCompleteLesson(lessonsList);
    }

    if (auth === "notAuthorized" && localStorage.getItem("completed_level")) {
      return localStorage.getItem("completed_level")!.split(",").length;
    }

    return 0;
  }, [lessonsList, auth]);

  const getCub = () => {
    const lessonsLength = lessonsList.length;

    if (getCompleted < lessonsLength - 3) {
      return emptyCub;
    }

    if (getCompleted < lessonsLength - 1) {
      return bronze;
    }

    if (getCompleted === lessonsLength - 1) {
      return silver;
    }
    return gold;
  };

  return (
    <div className="lessons-nav">
      <RoundButton
        image={arrow}
        onClick={() => {
          history.push("/levels");
        }}
        alt="arrow"
        className="lessons-nav_circle margin-bottom_20"
        imageClassName="lessons-nav-image lessons-nav_circle-arrow"
      />
      <RoundButton
        image={homeIcon}
        onClick={() => {
          history.push("/home");
        }}
        alt="arrow"
        className="lessons-nav_circle lessons-button_margin"
        imageClassName="lessons-nav-image lessons-nav_circle-house"
      />
      <div className="lessons-nav__cub margin-bottom_10">
        <img src={getCub()} alt="cub" className={cubClassName} />
      </div>
      <div className="lessons-nav_progress">
        <img src={star} className="lessons-nav_progress-image" alt="star" />
        <span className="lessons-nav_progress-num">
          {getCompleted} / {lessonsList.length}
        </span>
      </div>
    </div>
  );
};

export default LessonsNav;
