import { get } from "lodash";
import { stopSubmit } from "redux-form";
import { History } from "history";

import {
  getProfileRequest,
  resendEmailRequest,
  setCertificateRequest,
  resetKeywordRequest,
  setEmailRequest,
  setKeywordRequest,
  resetProfilePasswordRequest,
} from "./api";
import {
  IProfile,
  IResetProfilePassword,
  ISetCertificate,
  ISetEmail,
  ISetKeyword,
  SetIsDataUploadType,
  ActionsType,
} from "./types";
import { Dispatch } from "redux";
import { KeywordType } from "../../../types";

export const actions = {
  saveProfile: (payload: IProfile) =>
    ({ type: "profile/SAVE_PROFILE", payload } as const),
  setProfileLoading: (value: boolean) =>
    ({ type: "profile/PROFILE_LOADING", value } as const),
  setAdmin: (value: boolean) => ({ type: "profile/SET_ADMIN", value } as const),
  clearProfile: () => ({ type: "profile/CLEAR_PROFILE" } as const),
  setSuccessAlertProfile: (value: boolean) =>
    ({ type: "profile/SET_SUCCESS_ALERT_PROFILE", value } as const),
};

/**
 * Check local keyword
 *
 * @param formValue {string} - The value entered by the user
 * @param keyword {string} - Users keyword
 * @param history {Object} - Hook from useHistory
 * @param location {Object} - Hook from useLocation
 * @returns {function(*): void}
 */
export const checkKeyWord =
  (formValue: string, keyword: KeywordType, history: History, location: any) =>
  (dispatch: Dispatch<ActionsType>) => {
    if (formValue.trim() === keyword) {
      dispatch(actions.setAdmin(true));
      history.push(location.state?.from || "/cabinet");
    } else {
      dispatch(
        stopSubmit("singleFieldForm", {
          keyword: "Слово ключ уведене не правильно",
        })
      );
    }
  };

/**
 *  POST method '/profile/reset-keyword' - Reset keyword, send new keyword to email.
 *
 * @returns {function(*): Promise<unknown>}
 */
export const resetKeyword = () => (dispatch: Dispatch<ActionsType>) => {
  dispatch(actions.setProfileLoading(true));

  return resetKeywordRequest()
    .then((res: any) => {
      if (res.status === 200) {
        dispatch(actions.saveProfile(res.data));
        dispatch(actions.setProfileLoading(false));
        dispatch(actions.setSuccessAlertProfile(true));
      } else {
        dispatch(actions.setProfileLoading(false));
        dispatch(
          stopSubmit("enterKeyword", {
            keyword: "Щось лихе трапилося, попробуйте ще раз ",
          })
        );
      }
    })
    .catch((error: unknown) => {
      console.log(error);
    });
};

/**
 * GET method '/profile' - Get profile information.
 *
 * @param history {Object} - Hook from useHistory
 * @param setIsDataUpload {function} -
 * @returns {function(*): Promise<unknown>}
 */
export const getProfile = (
    history: History,
    setIsDataUpload: SetIsDataUploadType = () => {}
  ) => (dispatch: Dispatch<ActionsType>) => {
    dispatch(actions.setProfileLoading(true));

    return getProfileRequest()
      .then((res: any) => {
        if (res.status === 200) {
          dispatch(actions.saveProfile(res.data));
          dispatch(actions.setProfileLoading(false));
        }
        if (res.status === 401) {
          dispatch(actions.setProfileLoading(false));
          setIsDataUpload(true);
          // history.push('/home');
        }
      })
      .catch((error: unknown) => {
        dispatch(actions.setProfileLoading(false));
        console.log(error);
      });
  };

/**
 * PATCH method '/profile/key-word' - Update the users keyword.
 *
 * @param params {Object} - Object with new keyword.
 * @returns {function(*): Promise<unknown>}
 */
export const setKeyword =
  (params: ISetKeyword) => (dispatch: Dispatch<ActionsType>) => {
    dispatch(actions.setProfileLoading(true));
    return setKeywordRequest(params)
      .then((res: any) => {
        if (res.status === 202) {
          dispatch(actions.saveProfile(res.data));
          dispatch(actions.setProfileLoading(false));
          dispatch(actions.setSuccessAlertProfile(true));
        }
        dispatch(actions.setProfileLoading(false));
      })
      .catch((error: unknown) => {
        dispatch(actions.setProfileLoading(false));
        console.log(error);
      });
  };

/**
 * PATH method '/profile/password' - Update the user password.
 *
 * @param params {Object} - Object with new password
 * @param history {Object} - Hook from useHistory
 * @returns {function(*): Promise<unknown>}
 */
export const resetProfilePassword =
  (params: IResetProfilePassword, history: History) =>
  (dispatch: Dispatch<ActionsType>) => {
    dispatch(actions.setProfileLoading(true));

    return resetProfilePasswordRequest(params)
      .then((res: any) => {
        if (res.status === 202) {
          dispatch(actions.saveProfile(res.data));
          dispatch(actions.setProfileLoading(false));
          dispatch(actions.setSuccessAlertProfile(true));
        }

        if (res.status === 401 || res.status === 422) {
          dispatch(actions.setProfileLoading(false));
          history.push("/");
        }
      })
      .catch((error: unknown) => {
        dispatch(actions.setProfileLoading(false));
        console.log(error);
      });
  };

/**
 * POST method '/email/resend' -  Resend verification email
 *
 * @returns {function(*): Promise<void>}
 */
export const resendEmail = () => (dispatch: Dispatch<ActionsType>) => {
  dispatch(actions.setProfileLoading(true));

  return resendEmailRequest()
    .then((res: any) => {
      if (res.status === 200) {
        dispatch(actions.setProfileLoading(false));
        dispatch(actions.setSuccessAlertProfile(true));
      }

      if (res.status === 401) {
        dispatch(actions.setProfileLoading(false));
      }
    })
    .catch((error: unknown) => {
      console.log(error);
    });
};

/**
 * PATH method '/profile/email' - Update the user email
 *
 * @param params {Object} - Object with new email
 * @param history {Object} - Hook from useHistory
 * @returns {function(*): Promise<unknown>}
 */
export const setEmail =
  (params: ISetEmail, history: History) =>
  (dispatch: Dispatch<ActionsType>) => {
    dispatch(actions.setProfileLoading(true));
    return setEmailRequest(params)
      .then((res: any) => {
        if (res.status === 202) {
          dispatch(actions.saveProfile(res.data));
          dispatch(actions.setProfileLoading(false));
          history.push("/settings/verification");
        }

        if (res.status === 422) {
          const message = get(
            res,
            "error.message",
            "Мабуть ви вказали не валідний email"
          );
          const action = stopSubmit("singleFieldForm", { email: message });

          dispatch(action);
          dispatch(actions.setProfileLoading(false));
        }

        if (res.status === 401) {
          const message = get(
            res,
            "error.message",
            "Не авторизований користувач не може встановити email"
          );
          const action = stopSubmit("singleFieldForm", { email: message });

          dispatch(action);
          dispatch(actions.setProfileLoading(false));

          setTimeout(() => {
            history.push("/auth/login");
          }, 1500);
        }
      })
      .catch((error: unknown) => {
        console.log(error);
        dispatch(actions.setProfileLoading(false));
      });
  };

/**
 * 'POST' method '/leads/send-to-crm' - Send data what course is completed to optima crm
 *
 * @param params {Object} - Fields with information about user
 * @param activeChildId {number | null} - Redux value. Active child id
 * @param history {Object} - Hook from useHistory
 * @returns {function(*): Promise<unknown>}
 */
export const setCertificate =
  (activeChildId: number | null, params: ISetCertificate, history: History) =>
  (dispatch: Dispatch<ActionsType>) => {
    dispatch(actions.setProfileLoading(true));

    return setCertificateRequest(activeChildId, params)
      .then((res: any) => {
        if (res.status === 200) {
          dispatch(actions.setSuccessAlertProfile(true));
          dispatch(actions.setProfileLoading(false));
        }
      })
      .catch((error: unknown) => {
        console.log(error);
      });
  };
