import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import profileSelector from "../../store/modules/profile/selector";
import paymentSelector from "../../store/modules/payment/selector";
import { logOut } from "../../store/modules/auth/actions";
import {
  getProfile,
  actions as actionsProfile,
} from "../../store/modules/profile/actions";
import LoadingModal from "../../components/modals/LoadingModal";
import question from "../../assets/icons/home/question.svg";
import logout from "../../assets/icons/log-out.svg";
import NavElement from "./components/NavElement";
import RoundButton from "../../components/buttons/RoundButton";
import HintModal from "../../components/modals/HintModal";
import BackgroundVideo from "../../components/BackgroundVideo";
import { getNavList } from "../helpers";
import AreSureModal from "../../components/modals/AreSureModal";
import CabinetButton from "./components/CabinetButton";
import authSelector from "../../store/modules/auth/selector";
import RegisterModal from "../../components/modals/RegisterModal";
import { AppStateType } from "../../store/rootReducer";
import { IProfile } from "../../store/modules/profile/types";
import { History } from "history";
import { Authorization } from "../../store/modules/auth/types";
import { ISubCourseInfo } from "../../store/modules/payment/types";
import childrenSelector from "../../store/modules/children/selector";
import { getCourseSub } from "../../store/modules/payment/actions";
import "./home.scss";
import { getProgressData } from '../../helpers';
import { UnregisterSaveProgressMode } from '../../constants/enums';

export interface IHints {
  icon: string;
  text: string;
  path: string;
  key: string;
  available: boolean;
}

interface IMapState {
  profile: IProfile | null;
  loadingProfile: boolean;
  auth: Authorization | null;
  isShowSuccessAlert: boolean;
  loadingPayment: boolean;
  subscriptionInfo: ISubCourseInfo | null;
  activeChildId: number | null;
  subCourseInfo: ISubCourseInfo | null;
}

interface IMapDispatch {
  logOut: () => void;
  getProfile: (history: History) => void;
  setAdmin: (value: boolean) => void;
  getCourseSub: (childId: number, history: History) => void;
}

type Props = IMapState & IMapDispatch;

/**
 *
 * @param profile{Object} - Redux value. Object with data of profile
 * @param logOut{function} - Action. Action what clear reducer and localStorage
 * @param setAdmin{function} - Action. Action what make user not admin.
 * @param getProfile{function} - Action. Action get data of profile from api
 * @param loading{boolean} - Redux value. Show data of loading or no.
 * @param auth{string} - Show auth user or not
 * @returns {JSX.Element}
 * @constructor
 */
const Home: React.FC<Props> = ({
  profile,
  logOut,
  getProfile,
  loadingProfile,
  auth,
  setAdmin,
  loadingPayment,
  subscriptionInfo,
  activeChildId,
  getCourseSub,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLogOutModal, setIsLogOutModal] = useState(false);
  const [isRegisterModal, setIsRegisterModal] = useState(false);


  const history = useHistory();
  const { t } = useTranslation();

  const navigation: IHints[] = getNavList(t, auth, subscriptionInfo);

  useEffect(() => {
    const idOfChild =
      activeChildId || +localStorage.getItem("active_child_id")!;

    if (auth === "authorized") {
      getProfile(history);
      getCourseSub(idOfChild, history);
    }

    setAdmin(false);
  }, [auth]);

  useEffect(() => {
    if (subscriptionInfo && subscriptionInfo.schoolDiscountStatus === "ready") {
      history.push("/main-cub");
    }
  }, [subscriptionInfo]);

  useEffect(() => {
    if (profile && !profile.children.length) {
      history.push("/settings/add-child");
    }
  }, [profile]);

  const getHintModal = () => {
    if (isModalOpen) {
      return (
        <HintModal
          elements={navigation}
          textButton={t("Home.hintModalButton")}
          onClick={() => setIsModalOpen(false)}
        />
      );
    }

    return null;
  };

  const getLogOutModal = () => {
    if (isLogOutModal) {
      return (
        <AreSureModal
          closeModal={() => setIsLogOutModal(false)}
          successModal={toLogOut}
          text={t("common.AreSureModal.textHome")}
          forChild
          horizontal
        />
      );
    }

    return null;
  };

  const getRegisterModal = () => {
    if (isRegisterModal) {
      return (
        <RegisterModal
          laterClick={() => setIsRegisterModal(false)}
          registerClick={() => history.push("/auth/sign")}
          mainButtonText={t("common.RegisterHomeModal.registerButton")}
          showLetterButton
          textBlueButton={t("common.RegisterHomeModal.letterButton")}
          loginClick={() => history.push("/auth/login")}
        />
      );
    }

    return null;
  };

  const getNavElement = () => {
    return navigation.map(({ icon, path, key, available }) => (
      <NavElement
        icon={icon}
        key={key}
        available={available}
        onClick={() => history.push(path)}
      />
    ));
  };

  const toLogOut = (e: React.MouseEvent<HTMLElement>) => {
    logOut();
    history.push("/auth/login");
  };

  if (loadingProfile || loadingPayment) {
    return <LoadingModal />;
  }

  return (
    <>
      {getLogOutModal()}

      {getRegisterModal()}

      <div className="vertical-screen">
        <BackgroundVideo />

        {getHintModal()}

        <div className="home">
          <div className="home__nav">{getNavElement()}</div>
          <CabinetButton
            profile={profile}
            auth={auth}
            setRegisterModal={() => setIsRegisterModal(true)}
          />
          <RoundButton
            className="position__left-bottom lessons-nav_circle"
            imageClassName="lessons-nav-image"
            image={question}
            alt="question"
            onClick={() => setIsModalOpen(true)}
          />
          {auth === "authorized" && (
            <RoundButton
              className="position__right-bottom lessons-nav_circle"
              imageClassName="lessons-nav-image"
              image={logout}
              alt="logOut"
              onClick={() => setIsLogOutModal(true)}
            />
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: AppStateType): IMapState => ({
  profile: profileSelector.getProfile(state),
  loadingProfile: profileSelector.getLoading(state),
  isShowSuccessAlert: profileSelector.getIsShowSuccessAlert(state),
  auth: authSelector.getAuth(state),
  loadingPayment: paymentSelector.getInfoLoading(state),
  subscriptionInfo: paymentSelector.getInfoSubscription(state),
  activeChildId: childrenSelector.getActiveChildId(state),
  subCourseInfo: paymentSelector.getInfoSubscription(state),
});

const mapDispatchToProps: IMapDispatch = {
  getProfile,
  logOut,
  setAdmin: actionsProfile.setAdmin,
  getCourseSub,
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
