import React, { useEffect, useRef, useState, memo } from "react";
import classNames from "classnames";
import { ReactComponent as RotateImg } from "../../assets/icons/screen-rotation-button.svg";
import {
  getHeightDevice,
  getIsPortrait,
  getOrientation,
  getWidthDevice, isAndroid,
  isIOS,
  visibilityStateStart,
} from "../../helpers";
import "./gameModule.scss";
import { IReadySounds } from '../../store/modules/media/types';
import useDidMountEffect from '../../hooks/useDidMountEffect';

declare global {
  interface Window {
    optimaGameWidget: any;
  }
}

interface IProps {
  setPartsCount: (prevState: any) => any
  configData: any
  isAnimation: boolean
  setIsShouldReload: (prevState: boolean) => void
  audio: IReadySounds | null
  isPause: boolean
}

/**
 * Module what init the game.
 *
 * setPartsCount {function} - Function what set what part is completed.
 * configData {Object} - Object with gameData
 * @returns {JSX.Element}
 */

const GameModule: React.FC<IProps> = ({
                                        setPartsCount,
                                        configData,
                                        isAnimation,
                                        setIsShouldReload,
                                        isPause,
                                        audio
                                      }) => {
  const [orientation, setOrientation] = useState(getOrientation());
  const [gameBody, setGameBody] = useState(true);
  const isAnimationRef = useRef(false);

  // @ts-ignore
  const element = useRef<HTMLDivElement>(); // needs for tie eventListeners to div element
  const isInit = useRef(false);
  const isGameReady = useRef(false)

  useEffect(() => {

    changeSize();

    document.addEventListener('visibilitychange', visibilityChange, false);

    window.addEventListener("resize", changeSize, false);

    return () => {
      window.removeEventListener("resize", changeSize, false);

      document.addEventListener( 'visibilitychange', visibilityChange, false);

      removeEventListeners(); // remove Event listeners for current game for clear memory

      window.optimaGameWidget("destroy");
    };
  }, []);

  useDidMountEffect(() => {
    if (isAnimation) return;

    if (isPause) {
      console.log('PAUSE')
      window.optimaGameWidget("pause");
    }
    if (!isPause) {
      console.log('RESUME')
      window.optimaGameWidget("resume");
    }
  },[isAnimation, isPause])

  useEffect(() => {
    // window.optimaGameWidget("destroy");
    let timeOut: NodeJS.Timeout;
    if (!isInit.current && !getIsPortrait(orientation)) {
      timeOut = setTimeout(() => {
        window.optimaGameWidget("start", {
          gameData: configData, // JSON game config
          parent: "game-widget-container", // HTMLElement or element id
          width: Math.floor(getWidthDevice() * 100),
          height: Math.floor(getHeightDevice() * 100),
        });
        isInit.current = true;
      }, 200);
    }
    return () => {
      if (timeOut) {
        clearTimeout(timeOut)
      }
    }
  }, [orientation, configData]);

  useEffect(() => {
    addEventListeners();

    return () => {
      removeEventListeners();
    };
  }, [configData, isAnimation, audio]);

  useDidMountEffect(() => {
    if (isInit.current) {
      isAnimationRef.current = isAnimation;
      animationLogic();
    }

  }, [isAnimation]);

  const animationLogic = () => {
    setTimeout(() => {
      if (isAnimation) {
        console.log('PAUSE');
        window.optimaGameWidget("pause");
      }
      if (!isAnimation) {
        console.log('PLAY INTRO');
        window.optimaGameWidget("pause");
        window.optimaGameWidget("playIntro");
      }
    }, 150);
  };

  const visibilityChange = () => {
    if (document.visibilityState === 'hidden' && (isIOS() || isAndroid())) {
      localStorage.setItem(visibilityStateStart, Date.now().toString())
    }

    if (document.visibilityState === 'visible' && (isIOS() || isAndroid())) {
      const start = localStorage.getItem(visibilityStateStart)
      if (start && Date.now() - +start > 300000) {
        localStorage.removeItem(visibilityStateStart)
        window.location.reload();
      }
    }
  }

  const changeSize = () => {
    setTimeout(() => {
      setOrientation(getOrientation());

      if (getIsPortrait(getOrientation()) || !isInit.current || !isGameReady.current) {
        return;
      } else {
        window.optimaGameWidget("scale", {
          width: Math.floor(getWidthDevice() * 100),
          height: Math.floor(getHeightDevice() * 100),
        });
      }
    }, 200);
  };

  const removeEventListeners = () => {
    if (element.current) {
      element.current.removeEventListener("GAME_IS_LOADED", gameIsLoaded);

      element.current.removeEventListener("GAME_STARTED", gameIsStarted);

      element.current.removeEventListener("WRONG_ANSWER", wrongAnswer);

      element.current.removeEventListener("GAME_COMPLETED", gameCompleted);

      element.current.removeEventListener("WIN_ACTION", winAction);

      element.current.removeEventListener("GAME_READY", gameReady);
    }
  };

  const addEventListeners = () => {
    if (element.current) {
      element.current.addEventListener("GAME_IS_LOADED", gameIsLoaded);

      element.current.addEventListener("GAME_STARTED", gameIsStarted);

      element.current.addEventListener("WRONG_ANSWER", wrongAnswer);

      element.current.addEventListener("CORRECT_ANSWER", correctAnswer);

      element.current.addEventListener("GAME_COMPLETED", gameCompleted);

      element.current.addEventListener("WIN_ACTION", winAction);

      element.current.addEventListener("GAME_READY", gameReady);
    }
  };

  const gameIsLoaded = () => {
  };

  const gameIsStarted = () => {
  };

  const wrongAnswer = (e: any) => {
    e.preventDefault();
   if (audio?.failed && audio.failed.count) {
     const index = +Math.floor(Math.random() * audio.failed.count);
     audio.failed.items[index].play()
       .catch((error) => {
       console.warn('warn', error)
       setIsShouldReload(true)
     });
   }
  };

  const correctAnswer = (e: any) => {
    e.preventDefault();
    if (audio?.success && audio.success.count) {
      const index = +Math.floor(Math.random() * audio.success.count);
      audio.success.items[index].play()
        .catch((error) => {
          console.warn('warn', error)
          setIsShouldReload(true)
        });
    }

  };

  const gameReady = () => {
    isGameReady.current = true;
    animationLogic();
  }

  const winAction = () => {
  };

  const gameCompleted = (e: any) => {
    removeEventListeners(); // remove Event listeners for current game for clear memory

    window.optimaGameWidget("destroy");

    setGameBody(false); // destroy parent container for rebuild game

    setTimeout(() => {
      setCounts();
    }, 50);
  };

  const setCounts = () => {
    isInit.current = false;

    setGameBody(true); // create parent container for game

    setPartsCount((prevState: number) => prevState + 1); // c
  };

  const getPortraitContent = () => {
    if (getIsPortrait(orientation)) {
      return (
        <div className="portrait__container flex-center">
          <RotateImg className="portrait-img" />
        </div>
      );
    }

    return null;
  };

  const gameBodyElement = (
    <div
      // @ts-ignore
      ref={element}
      style={{
        pointerEvents: isPause ? 'none' : 'auto',
      }}
      id="game-widget-container"
      className={classNames("game-container", {
        opacity: getIsPortrait(orientation),
      })}
    />
  );


  return (
    <>
      {getPortraitContent()}
      {gameBody && gameBodyElement}
    </>
  );
};

export default memo(GameModule);
