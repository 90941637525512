import React, { ReactElement } from "react";
import Modal from "react-modal";
import MainButton from "../../buttons/MainButton";
import "./registerModal.scss";
import { useTranslation } from "react-i18next";

interface IRegisterModal {
  laterClick: () => void;
  registerClick: () => void;
  mainButtonText: string;
  showLetterButton?: boolean;
  textBlueButton?: string;
  loginClick: () => void;
  mainLeftIcon?: | (() => ReactElement<any>)
}

/**
 *
 * @param laterClick{function} - Causes function when click in blue button.
 * @param registerClick{function} - Causes function when click in main button
 * @param mainButtonText{string | ReactElement} - Text in the main button
 * @param showLetterButton{boolean} - Shows blue button or not
 * @param textBlueButton{string} - Text in the blue button
 * @param loginClick{function} - Login
 * @param mainLeftIcon{function | undefined} - First Button Icon
 * @returns {JSX.Element}
 * @constructor
 */
const RegisterModal: React.FC<IRegisterModal> = ({
  laterClick,
  registerClick,
  mainButtonText,
  mainLeftIcon,
  showLetterButton = true,
  textBlueButton = "",
  loginClick
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen
      ariaHideApp={false}
      contentLabel="Example Modal"
      className="hint-modal hint-modal__horizontal"
      overlayClassName="overlay overlay-dark"
    >
      {/*<div className="text-center margin-bottom_40 verification-modal__text">*/}
      {/*  {mainText}*/}
      {/*</div>*/}

      <MainButton
        text={t('common.RegisterHomeModal.loginButton')}
        className="login__button-blue margin-bottom_20"
        onClick={loginClick}
        leftIcon={mainLeftIcon}
      />

      {/*<button type={'button'} onClick={loginClick}*/}
      {/*   className={'login__button-blue margin-bottom_20'}>*/}
      {/*  {t('common.RegisterHomeModal.loginButton')}*/}
      {/*</button>*/}

      <MainButton
        text={mainButtonText}
        className="login__button-green margin-bottom_20"
        onClick={registerClick}
      />

      {showLetterButton && (
        <div
          className="text-blue register__main-button text-center"
          onClick={laterClick}
          onKeyDown={laterClick}
        >
          {textBlueButton}
        </div>
      )}
    </Modal>
  );
};

export default RegisterModal;
