import React, { FC } from "react";
import Modal from "react-modal";
import "./index.scss";
import { ReactComponent as Play } from "../../../assets/icons/play.svg";
import { ReactComponent as Restart } from "../../../assets/icons/restart.svg";

interface IProgressModal {
  onRestartClick: () => void;
  onPlayClick: () => void;
  onBackgroundClick: () => void;
}

const ProgressModal: FC<IProgressModal> = ({
  onRestartClick,
  onPlayClick,
  onBackgroundClick,
}) => {
  return (
    <Modal
      isOpen
      ariaHideApp={false}
      contentLabel="Example Modal"
      className="hint-modal hint-modal__horizontal progress__modal"
      overlayClassName="overlay overlay-dark"
      onRequestClose={onBackgroundClick}
    >
      <div className="progress__content">
        <span className="progress__button" onClick={onRestartClick}>
          <Restart />
        </span>
        <span className="progress__button" onClick={onPlayClick}>
          <Play />
        </span>
      </div>
    </Modal>
  );
};

export default ProgressModal;
