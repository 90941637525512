import React, { useRef } from "react";
import ReactPlayer from "react-player";

interface IProps {
  completedPart: () => void;
  source: string;
}
/**
 *  Component when start video from youTube
 *
 * @param completedPart {function} - Function what completed parts.
 * @param source {string}  - string with video.
 * @returns {JSX.Element}
 * @constructor
 */
const VideoModule: React.FC<IProps> = ({ completedPart, source }) => {
  const player = useRef(null);

  // TODO need to change because source is string
  const link = JSON.parse(source).src;

  const config = {
    url: link,
    controls: true,
    width: "100%",
    height: "100%",
    playing: true,
    style: {
      width: "100%",
      height: "100%",
      background: "#000000"
    },
    config: {
      youtube: {
        playerVars: { controls: 1 },
      },
      vimeo: {
        playerOptions: {
          autoplay: true,
          // responsive: true,
          portrait: false,

        }
      },
    },
  };

  return (
    <div className="vertical-screen">
      <div style={{ width: "100%", height: "100%", overflow: "hidden" }}>
        {/* @ts-ignore*/}
        <ReactPlayer ref={player} onEnded={completedPart} {...config} />
      </div>
    </div>
  );
};

export default VideoModule;
