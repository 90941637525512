import { MediaPathMode, Seasons } from '../constants/enums';
import { useEffect, useState } from 'react';

const useMediaPath = (mediaPathMode: MediaPathMode) => {
	const [path, setPath] = useState<string>();
	const data = new Date().getMonth();
	const BASE = process.env.REACT_APP_BACKEND_ASSETS_BASE_URL;
	let season

	const uploadImage = (src: string) => {
		const img = new Image()
		img.src = src
		img.onload = () => setPath(src)
	}

	if (!path) {
		if (data === 11 || data === 0 || data === 1) {
			season = Seasons.Winter
		}
		if (data >= 2 && data <= 4) {
			season = Seasons.Spring
		}
		if (data >= 5 && data <= 7) {
			season = Seasons.Summer
		}
		if (data >= 8 && data <= 10) {
			season = Seasons.Autumn
		}

		switch (mediaPathMode) {
			case MediaPathMode.LessonLevel:
				setPath(`${BASE}/seasons/levels/small_${season}.jpeg`);
				uploadImage(`${BASE}/seasons/levels/${season}.jpeg`);
				break;
			case MediaPathMode.LessonLesson:
				setPath(`${BASE}/seasons/lessons/small_${season}.jpeg`);
				uploadImage(`${BASE}/seasons/lessons/${season}.jpeg`);
				break;
			case MediaPathMode.ArcadeCategories:
				setPath(`${BASE}/seasons/arcade-categories/small_${season}.jpeg`);
				uploadImage(`${BASE}/seasons/arcade-categories/${season}.jpeg`);
				break;
			case MediaPathMode.Arcades:
				setPath(`${BASE}/seasons/arcades/small_${season}.jpeg`);
				uploadImage(`${BASE}/seasons/arcades/${season}.jpeg`);
				break;
			case MediaPathMode.ArcadeLevels:
				setPath(`${BASE}/seasons/arcade-levels/small_${season}.jpeg`);
				uploadImage(`${BASE}/seasons/arcade-levels/${season}.jpeg`);
				break;
			case MediaPathMode.HomeVideo:
				setPath(`${BASE}/videos/${season}.mp4`);
				break;
			default:
				setPath(`${BASE}/seasons/levels/small_${season}.jpeg`);
				uploadImage(`${BASE}/seasons/levels/${season}.jpeg`);
		}
	}

	return path;
}

export default useMediaPath;
