import Cookies from 'js-cookie'
import { stopSubmit } from "redux-form";
import { get } from "lodash";
import { removeTokens } from "../../request";
import {
	getTokenEmailRequest,
	getTokenLoginRequest,
	getTokenOauthRequest,
	resetPasswordRequest,
	resetPasswordSendEmailRequest,
} from "./api";
import { actions as actionsStudyZone } from "../studyZone/actions";
import { actions as actionsProfile } from "../profile/actions";
import {
	ActionsType,
	AuthType,
	EmailType,
	getTokenOauthRequestType,
	ProviderType,
	ResetFieldsType,
} from "./types";
import { ActionsType as ActionsTypeGameZone, ILocalSavingProgress } from "../studyZone/types";
import { ActionsType as ActionsTypeProfile } from "../profile/types";
import { Dispatch } from "redux";
import { History } from "history";
import { completed_level, getLocalSavingProgress, lessons_saving_progress, setLocalStorage } from '../../../helpers';
import { UnregisterSaveProgressMode } from '../../../constants/enums';

declare global {
	interface Window {
		FB: any;
	}
}

type LogOutDispatch = ActionsType | ActionsTypeGameZone | ActionsTypeProfile;

export const actions = {
	saveToken: (token: string, refreshToken: string, tokenType: string, expiresId = 31536) => {
		Cookies.set('auth_token', token, { domain: process.env.REACT_APP_DOMAIN, expires: expiresId })
		Cookies.set('refresh_token', refreshToken, { domain: process.env.REACT_APP_DOMAIN, expires: expiresId })
		Cookies.set('token_type', tokenType, { domain: process.env.REACT_APP_DOMAIN, expires: expiresId })

		return {
			type: "auth/SAVE_TOKENS",
			token,
			refreshToken,
			tokenType,
		} as const;
	},
	deleteToken: () => ({ type: "auth/DELETE_TOKENS" } as const),
	setLoading: (value: boolean) => ({ type: "auth/LOADING", value } as const),
	setAlert: (value: boolean) =>
		({ type: "auth/SET_SUCCESS_ALERT", value } as const),
	setNotAuthorized: () => ({ type: "auth/SET_NOT_AUTHORIZED" } as const),
};

export const logOut = () => (dispatch: Dispatch<LogOutDispatch>): void => {
    if (localStorage.getItem("social_provider") === "facebook" && !!window.FB) {
        window.FB.logout();
    }

	const localSavingProgress: ILocalSavingProgress | null = getLocalSavingProgress(UnregisterSaveProgressMode.Lesson);
	const completedLevels = localStorage.getItem(completed_level);

	localStorage.clear();
	removeTokens();
	dispatch(actionsStudyZone.clearLevels());
	dispatch(actionsProfile.clearProfile());
	dispatch(actions.deleteToken());

	if (localSavingProgress) {
		setLocalStorage(lessons_saving_progress, JSON.stringify(localSavingProgress)).then()
	}
	if (completedLevels) {
		setLocalStorage(completed_level, completedLevels).then();
	}
};

export const setAuthLoading = (value: boolean) => (dispatch: Dispatch<ActionsType>) => {
	dispatch(actions.setLoading(value));
};

export const setSuccessAlert = (value: boolean) => (dispatch: Dispatch<ActionsType>) => {
	dispatch(actions.setAlert(value));
};

/**
 *  Check token from localStorage if tokens are available make user 'authorized' if not available 'notAuthorized'
 *
 * @returns {function(*): void}
 */
export const setMe = () => (dispatch: Dispatch<ActionsType>): void => {

	const token = Cookies.get('auth_token');
	const tokenType = Cookies.get('token_type');
	const refreshToken = Cookies.get('refresh_token');


	if (token && tokenType && refreshToken) {
		dispatch(actions.saveToken(token, refreshToken, tokenType));
	} else {
		dispatch(actions.setNotAuthorized());
	}
};

/**
 * POST method '/oauth/token' - Login or Register user through social network.
 *
 * @param token {string} - Token from social.
 * @param provider {'google' || 'facebook'} - The social network through which they registered
 * @param history {Object} - Hook from useHistory
 * @returns {function(*): Promise<unknown>}
 */
export const getTokenSocial =
	(token: string, provider: ProviderType, history: History) =>
		(dispatch: Dispatch<ActionsType>) => {
			const params: getTokenOauthRequestType = {
				provider,
				access_token: token,
			};

			return getTokenOauthRequest(params)
				.then((res: any) => {
					if (res.status === 200) {
						dispatch(
							actions.saveToken(
								res.data.access_token,
								res.data.refresh_token,
								res.data.token_type,
								res.data.expires_in
							)
						);
						dispatch(actions.setLoading(false));

						localStorage.setItem("register_from", "social");
						localStorage.setItem("social_provider", provider);

						history.push("/");
					}

					if (res.status === 401 || res.status === 500) {
						const message = get(res, "error.message", "Трапилося щось не те");
						const action = stopSubmit("signIn", { email: message });
						const actionLogin = stopSubmit("Login", { email: message });

						dispatch(action);
						dispatch(actionLogin);
						dispatch(actions.setLoading(false));
					}
				})
				.catch((error: any) => {
					dispatch(actions.setLoading(false));
					console.log(error);
				});
		};

/**
 * POST method '/register' - Register user throw fields email and password
 *
 * @param authData {Object} - Object with email and password
 * @param history {Object} - Hook from useHistory()
 * @returns {function(*): Promise<unknown>}
 */
export const getTokenSignIn =
	(authData: AuthType, history: History) =>
		(dispatch: Dispatch<ActionsType>) => {
			dispatch(actions.setLoading(true));

			return getTokenEmailRequest(authData)
				.then((res: any): void => {
					if (res.status === 422 || res.status === 400 || res.status === 500) {
						const message = get(
							res,
							"error.fields.email[0]",
							"Сталося щось не те"
						);
						const action = stopSubmit("signIn", { email: message });

						dispatch(action);
						dispatch(actions.setLoading(false));
					}
					if (res.status === 200) {
						dispatch(
							actions.saveToken(
								res.data.access_token,
								res.data.refresh_token,
								res.data.token_type,
								res.data.expires_in
							)
						);
						dispatch(actions.setLoading(false));
						history.push("/");
					}
				})
				.catch((error: any): void => {
					console.log(error);
					dispatch(actions.setLoading(false));
				});
		};

/**
 * POST method '/oauth/token' - Login with email and password
 *
 * @param authData {Object} - Object with email and password
 * @param history {Object} - Hook from useHistory
 * @returns {function(*): Promise<unknown>}
 */
export const getTokenLogin =
	(authData: AuthType, history: History) =>
		(dispatch: Dispatch<ActionsType>) => {
			dispatch(actions.setLoading(true));

			const params: AuthType = {
				email: authData.email,
				password: authData.password,
			};

			return getTokenLoginRequest(params)
				.then((res: any) => {
					if (res.status === 200) {
						dispatch(
							actions.saveToken(
								res.data.access_token,
								res.data.refresh_token,
								res.data.token_type,
								res.data.expires_in
							)
						);
						dispatch(actions.setLoading(false));

						history.push("/");
					}

					if (res.status === 401) {
						const action = stopSubmit("Login", {
							_error: "Невірно введений логін або пароль",
						});

						dispatch(action);
						dispatch(actions.setLoading(false));
					}

					if (res.status === 400 || res.status === 500) {
						const action = stopSubmit("Login", { _error: "Сталося щось не те" });

						dispatch(action);
						dispatch(actions.setLoading(false));
					}
				})
				.catch((error: any) => {
					dispatch(actions.setLoading(false));
					console.log(error);
				});
		};

/**
 * POST method 'password/email' - Sends forgot password email
 *
 * @param param {Object} - Object with email field.
 * @returns {function(*): Promise<unknown>}
 */
export const resetPasswordSendEmail =
	(param: EmailType) => (dispatch: Dispatch<ActionsType>) => {
		dispatch(actions.setLoading(true));

		return resetPasswordSendEmailRequest(param)
			.then((res: any) => {
				if (res.status === 200) {
					dispatch(actions.setLoading(false));
					dispatch(actions.setAlert(true));
				} else {
					const message = get(
						res,
						"error.fields.email[0]",
						"Сталася помилка але ми вже її вирішуємо"
					);

					const action = stopSubmit("singleFieldForm", { email: message });

					dispatch(action);
					dispatch(actions.setLoading(false));
				}
			})
			.catch((error: any) => {
				dispatch(actions.setLoading(false));
				console.log(error);
			});
	};

/**
 * POST method '/password/reset' - Reset the user password.
 *
 * @param auth {Object} - Object with email, password and token field.
 * @returns {function(*): Promise<unknown>}
 */
export const resetPassword =
	(auth: ResetFieldsType) => (dispatch: Dispatch<ActionsType>) => {
		dispatch(actions.setLoading(true));

		return resetPasswordRequest(auth)
			.then((res: any) => {
				if (res.status === 200) {
					dispatch(actions.setLoading(false));
					dispatch(actions.setAlert(true));
				} else {
					const message = get(
						res,
						"error.email",
						"Сталася якась прикра помилка, попробуйте знову ;)"
					);

					const action = stopSubmit("resetPassword", { password: message });

					dispatch(action);
					dispatch(actions.setLoading(false));
				}
			})
			.catch((error: any) => {
				console.log(error);
				dispatch(actions.setLoading(false));
			});
	};
