import cubes from "../assets/icons/home/cubes.svg";
import ball from "../assets/icons/ball.svg";
import star from "../assets/icons/home/star.svg";
import paintPallet from "../assets/icons/home/paint-pallet.svg";
import gold from "../assets/icons/cubs/gold.svg";
import silver from "../assets/icons/cubs/silver.svg";
import bronze from "../assets/icons/cubs/bronze.svg";
import empty from "../assets/icons/cubs/emptyCub.svg";
import whiteGold from "../assets/icons/cubs/white_bacground_gold.svg";
import whiteSilver from "../assets/icons/cubs/white_bacground_silver.svg";
import whiteBronze from "../assets/icons/cubs/white_bacground_bronze.svg";
import whiteEmpty from "../assets/icons/cubs/white_background_empty.svg";
import { IChild } from "../store/modules/children/types";
import { ICourse } from "../store/modules/studyZone/types";
import { ICub } from "../types";
import { Authorization } from "../store/modules/auth/types";
import { ISubCourseInfo } from "../store/modules/payment/types";

export const getNavList = (
  t: any,
  auth: Authorization | null,
  subscriptionInfo: ISubCourseInfo | null
) => {
  let isBallAvailable = false;
  let isPainPalletAvailable = false;

  if (subscriptionInfo && auth === "authorized") {
    isBallAvailable = subscriptionInfo.isArcadesActive;
    isPainPalletAvailable = subscriptionInfo.isCourseActive;
  }

  return [
    {
      icon: cubes,
      text: t("Home.cubes"),
      path: "/levels",
      key: "cubes",
      available: true,
    },
    {
      icon: ball,
      text: t("Home.ball"),
      path: "/game-categories",
      key: "ball",
      // available: isBallAvailable
      available: true,
    },
    {
      icon: star,
      text: t("Home.star"),
      path: "/achievements",
      key: "star",
      available: true,
    },
    {
      icon: paintPallet,
      text: t("Home.paintPallet"),
      path: "/book-of-stars",
      key: "paintPallet",
      // available: isPainPalletAvailable
      available: true,
    },
  ];
};

export const getCubsList = (activeChild: IChild, coursesDetails: ICourse) => {
  if (activeChild.progress) {
    return [
      {
        key: 11,
        icon: gold,
        count: activeChild.progress.golden_cups,
      },
      {
        key: 12,
        icon: silver,
        count: activeChild.progress.silver_cups,
      },
      {
        key: 13,
        icon: bronze,
        count: activeChild.progress.bronze_cups,
      },
      {
        key: 14,
        icon: empty,
        count: coursesDetails.levels_count - activeChild.progress.cups_count,
      },
    ];
  }
  return null;
};
export const getWhiteCubsList = (
  activeChild: IChild,
  coursesDetails: ICourse
): ICub[] | null => {
  if (activeChild.progress) {
    return [
      {
        key: 21,
        icon: whiteGold,
        count: activeChild.progress.golden_cups,
      },
      {
        key: 22,
        icon: whiteSilver,
        count: activeChild.progress.silver_cups,
      },
      {
        key: 23,
        icon: whiteBronze,
        count: activeChild.progress.bronze_cups,
      },
      {
        key: 24,
        icon: whiteEmpty,
        count: coursesDetails.levels_count - activeChild.progress.cups_count,
      },
    ];
  }
  return null;
};
export const getNotAuthCubList = (
  localCompleted: number,
  coursesDetails: ICourse
) => {
  const getGoldCount = localCompleted === 10 ? 1 : 0;
  const getSilverCount = localCompleted === 9 ? 1 : 0;
  const getBronzeCount = () => {
    if (localCompleted > 6 && localCompleted < 9) {
      return 1;
    }
    return 0;
  };
  const getEmptyCount = () => {
    return (
      coursesDetails.levels_count -
      getGoldCount -
      getSilverCount -
      getBronzeCount()
    );
  };

  return [
    {
      key: 21,
      icon: whiteGold,
      count: getGoldCount,
    },
    {
      key: 22,
      icon: whiteSilver,
      count: getSilverCount,
    },
    {
      key: 23,
      icon: whiteBronze,
      count: getBronzeCount(),
    },
    {
      key: 24,
      icon: whiteEmpty,
      count: getEmptyCount(),
    },
  ];
};
