import { AppStateType } from "../../rootReducer";

const getProfile = (state: AppStateType) => state.profile.profile;
const getIsAdmin = (state: AppStateType) => state.profile.isAdmin;
const getKeyWord = (state: AppStateType) => state.profile.profile?.key_word;
const getEmail = (state: AppStateType) => state.profile.profile?.email;
const getLoading = (state: AppStateType) => state.profile.loading;
const getIsShowSuccessAlert = (state: AppStateType) =>
  state.profile.isShowSuccessAlert;
const getAddChildFormData = (state: AppStateType) => state.form.addChild;
const getCompleteForm = (state: AppStateType) => state.form.completed;

export default {
  getProfile,
  getLoading,
  getIsShowSuccessAlert,
  getAddChildFormData,
  getKeyWord,
  getEmail,
  getIsAdmin,
  getCompleteForm,
};
