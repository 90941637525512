import React from "react";
import Modal from "react-modal";
import MainButton from "../../buttons/MainButton";
import "./successAlert.scss";

interface Props {
  text: string;
  onClick: () => void;
}
/**
 *
 * @param text{string} - Text in modal
 * @param onClick{function} - Function that is performed when you close the window
 * @returns {JSX.Element}
 * @constructor
 */
const SuccessAlert: React.FC<Props> = ({ text, onClick }) => {
  return (
    <Modal
      isOpen
      ariaHideApp={false}
      contentLabel="Example Modal"
      overlayClassName="overlay overlay-dark"
      className="success-modal"
    >
      <div className="success-alert__text">{text}</div>
      <MainButton
        type="submit"
        text="Зрозуміло"
        className="login__button-green"
        onClick={onClick}
      />
    </Modal>
  );
};

export default SuccessAlert;
